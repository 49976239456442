import React from "react";
import { Modal } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { LocalApiBaseUrl } from "../Shared/Constant";
import { trycatchAlertPopup } from "../Shared/Constant";
import axiosInstance from "../Shared/interceptor";
import _ from "lodash";
import i18n from "i18next";
import { regexForConfMasterFields } from "../Shared/Constant";
import { regexForTextAreas } from "../Shared/Constant";
import CloseIcon from "../Images/close-icon.svg";
import { isValidText,InvalidDescription } from "../Shared/commonFunctions"; 
import $ from "jquery";

axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


export default class OfferingPopUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      LocationOptions: [],
      IndustryOptions: [],
      OfferingOptions: [],
      ClientOptions: [],
      show: false,
      apivalue: "",
      selectValue: this.props.SelectValue,
      Display: false,
      errors: [],
      formIsValid: false,
      RequestorRemarksCharacterCount: 200,
      SubOffering:[
        {
          Name: i18n.t("1 Offering"),
          Id: 1,
          characterCount: 50,
          placeholder: i18n.t("1 Offering"),
          addNext: true,
          text: "",
          isSubOffering: false,
          OfferingId: 0,
          ParentID: null
        }     
      ],
      offering: {
        OfferingId: 0,
        OfferingName: "",
        Offering: "",
        ApproverRemarks: "",
        RequestorRemarks: "",
        IsRelatorBlock: false,
        IsValueBlock: false,
        IsNotificationBlock: false,
        IsArrowBlock: false,
        IsDetailsLink: false,
        IsIntelOpsCategory: false,
        IsBatDealLevel: this.props.data ? this.props.data.IsBatDealLevel : false,
        AvailableForDealCreation: false,
        Status: "",
        Process: "",
        IsActive: false,
        CreatedUser: "script",
        CreatedTS: "2019-01-19T18:47:18.05",
        UpdatedUser: "script",
        UpdatedTS: "2019-01-19T18:47:18.05",
        DealInfoes: [],
      },
      showTextbox: {
        show: false,
        showTextboxFrom: 0,
      },
      dropdownData: [
        {
          id: 1,
          data: this.props.allOfferingsData,
        },
        {
          id: 2,
          data: [],
        },
        {
          id: 3,
          data: [],
        },
        {
          id: 4,
          data: [],
        },
        {
          id: 5,
          data: [],
        },
        {
          id: 6,
          data: [],
        },
      ],
    };
    this.handleDropdownSelection = this.handleDropdownSelection.bind(this);
    this.getDropdownItemValue = this.getDropdownItemValue.bind(this);
    this.handleDealLevelRadioButtonChange = this.handleDealLevelRadioButtonChange.bind(this);
    this.onStatusChange = this.onStatusChange.bind(this);
    this.onValueBlockChange = this.onValueBlockChange.bind(this);
    this.onRelationBlockChange = this.onRelationBlockChange.bind(this);
    this.onNotificationBlockChange = this.onNotificationBlockChange.bind(this);
    this.onArrowBlockChange = this.onArrowBlockChange.bind(this);
    this.onDetailsLinkChange = this.onDetailsLinkChange.bind(this);
    this.handleAvailableforDealCreation = this.handleAvailableforDealCreation.bind(this);
    this.onOfferingNameChange = this.onOfferingNameChange.bind(this);
    this.onProcessChange = this.onProcessChange.bind(this);
    this.onRemarksChange = this.onRemarksChange.bind(this);
    this.characterCount=this.characterCount.bind(this);
    this.showNextLevel=this.showNextLevel.bind(this);
    this.removeSubLevel=this.removeSubLevel.bind(this);
    this.handleSubLevels=this.handleSubLevels.bind(this);
  }

  handleDealLevelRadioButtonChange(e) {
    let offeringState = { ...this.state.offering };
    if (e.target.value === "Deal Level") offeringState.IsBatDealLevel = true;
    else offeringState.IsBatDealLevel = false;

    this.setState({ offering: offeringState });
  }

  handleSave = (val) => {
    var Offering = _.cloneDeep(this.state.offering);
    Offering.subLevelList = [];  
    if (this.validateForm()) {
      if(val == "Save"){
        this.state.SubOffering.map( each => {
          if(each.Id !== 1){
            let data = {
              OfferingId: 0,
              OfferingName: each.text.trim(),
              level: each.Id,
              IsSubOffering: each.isSubOffering,              
              IsActive: this.state.offering.IsActive,        
            }
            Offering.subLevelList.push(data);
          }
          else{
            Offering.Offering=each.text.trim()
          }
        })
      }
      else{
        this.state.SubOffering.map( each => {
          if(each.Id !== 1){
            let data = {
              OfferingId: each.OfferingId,
              OfferingName: each.text.trim(),
              level: each.Id,
              IsSubOffering: each.isSubOffering,
              IsActive: this.state.offering.IsActive, 
              ParentID: each.ParentID ? each.ParentID : null       
            }
            Offering.subLevelList.push(data);
          }
          else{
            Offering.OfferingId = each.OfferingId
          }
        })
      }

      const Offering_extra = {
        ...Offering,
        subLevelList: Offering.subLevelList.map((each, index) => {
          if (index === Offering.subLevelList.length - 1) {
            return {
              ...each,
              IsSubOffering: true,
            }
          } else {
            return each;
          }
        })
      }

      this.props.onSave(Offering_extra,val);

      this.handleHide();
    }
  }

  handleHide(e) {
    this.setState({ show: false });
    if (!_.isNil(e)) {
      if (e.target.textContent !== "×") {
        this.props.onClose(e);
      } else {
        this.props.onClose(e);
      }
    } else {
      this.props.onClose(e);
    }
  }

  handleRejectHide(e) {
    this.setState({ show: false });
    if (!_.isNil(e)) {
      if (e.target.textContent !== "×") {
        this.props.onRejectClose();
      }
    } else {
      this.props.onRejectClose();
    }
  }

  handleApproveHide(e) {
    this.setState({ show: false });
    if (!_.isNil(e)) {
      if (e.target.textContent !== "×") {
        this.props.onApproveClose();
      }
    } else {
      this.props.onApproveClose();
    }
  }

  getOffering() {
    axiosInstance
      .get(`${LocalApiBaseUrl}Offering/GetOfferings`)
      .then((response) => {
        this.setState({ OfferingOptions: response.data });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  componentDidMount() {
    this.getOffering();
    this.setState({
      show: this.props.show,
    });
  }
  componentDidUpdate() {    
    $('.addoffering-modal').on('scroll',function(){
      ReactTooltip.hide();
     })
  }
  componentWillMount() {  
    let data = this.props.data;
    var maxChar = 50;
    if(data){
        let subOfferingValues = this.state.SubOffering;
        subOfferingValues.map(each=>{
          if(each.Id == 1){
            each.text = data.Offering;
            each.OfferingId = data.Sub_Level_1_ID;
            each.characterCount = maxChar - (data.Offering.length);
          }
          else if(each.Id == subOfferingValues.length){
            each.text = data.Process;  
            each.OfferingId = data.OfferingId;
            each.characterCount = maxChar - (data.Process.length);
            each.ParentID = data.Sub_Level_5_ID ? data.Sub_Level_5_ID : (data.Sub_Level_4_ID ? data.Sub_Level_4_ID: (data.Sub_Level_3_ID!=0 ? data.Sub_Level_3_ID : (data.Sub_Level_2_ID!=0 ? data.Sub_Level_2_ID : data.Sub_Level_1_ID)))
          }
        })
        let index=1;
        if(data.Sub_Level_2 != null && data.Sub_Level_2 != ""){
          let parent = `Sub_Level_${index}_ID`;
          parent = parent.substring(1, parent.length - 1)
          let value={
                  Name: (index+1) + " " +i18n.t("Sub Level"),
                  Id: index+1,
                  characterCount: maxChar - (data.Sub_Level_2.length),
                  placeholder: (index+1) + " " +i18n.t("Sub Level"),
                  addNext: true,
                  text: data.Sub_Level_2,
                  isSubOffering: false,    
                  ParentID: data.Sub_Level_1_ID,
                  OfferingId: data.Sub_Level_2_ID
                }            
              subOfferingValues.splice(index,0,value);
              this.handleSubLevels(index);
              index=index+1;
        }
        if(data.Sub_Level_3 != null && data.Sub_Level_3 != ""){
          let value={
                  Name: (index+1) + " " +i18n.t("Sub Level"),
                  Id: index+1,
                  characterCount: maxChar - (data.Sub_Level_3.length),
                  placeholder: (index+1) + " " +i18n.t("Sub Level"),
                  addNext: true,
                  text: data.Sub_Level_3,
                  isSubOffering: false,
                  ParentID: data.Sub_Level_2_ID!=0 ? data.Sub_Level_2_ID : data.Sub_Level_1_ID,
                  OfferingId: data.Sub_Level_3_ID
                }            
              subOfferingValues.splice(index,0,value);
              this.handleSubLevels(index);
              index=index+1;
        }
        if(data.Sub_Level_4 != null && data.Sub_Level_4 != ""){
          let value={
                  Name: (index+1) + " " +i18n.t("Sub Level"),
                  Id: index+1,
                  characterCount: maxChar - (data.Sub_Level_4.length),
                  placeholder: (index+1) + " " +i18n.t("Sub Level"),
                  addNext: true,
                  text: data.Sub_Level_4,
                  isSubOffering: false,
                  ParentID: data.Sub_Level_3_ID!=0 ? data.Sub_Level_3_ID : (data.Sub_Level_2_ID!=0 ? data.Sub_Level_2_ID : data.Sub_Level_1_ID),
                  OfferingId: data.Sub_Level_4_ID
                }            
              subOfferingValues.splice(index,0,value);
              this.handleSubLevels(index);
              index=index+1;
        }
        if(data.Sub_Level_5 != null && data.Sub_Level_5 != ""){
          let value={
                  Name: (index+1) + " " +i18n.t("Sub Level"),
                  Id: index+1,
                  characterCount: maxChar - (data.Sub_Level_5.length),
                  placeholder: (index+1) + " " +i18n.t("Sub Level"),
                  addNext: true,
                  text: data.Sub_Level_5,
                  isSubOffering: false,
                  ParentID: data.Sub_Level_4_ID ? data.Sub_Level_4_ID: (data.Sub_Level_3_ID!=0 ? data.Sub_Level_3_ID : (data.Sub_Level_2_ID!=0 ? data.Sub_Level_2_ID : data.Sub_Level_1_ID)),
                  OfferingId: data.Sub_Level_5_ID
                }            
              subOfferingValues.splice(index,0,value);
              this.handleSubLevels(index);
              index=index+1;
        }
        if(data.Process != null && data.Process != ""){
          let value={
                  Name: (index+1) + " " +i18n.t("Sub Level"),
                  Id: index+1,
                  characterCount: maxChar - (data.Process.length),
                  placeholder: (index+1) + " " +i18n.t("Sub Level"),
                  addNext: true,
                  text: data.Process,
                  isSubOffering: true,
                  ParentID: data.Sub_Level_4_ID ? data.Sub_Level_4_ID: (data.Sub_Level_3_ID!=0 ? data.Sub_Level_3_ID : (data.Sub_Level_2_ID!=0 ? data.Sub_Level_2_ID : data.Sub_Level_1_ID)),
                  OfferingId: 0,
                }            
              subOfferingValues.splice(index,0,value);
              this.handleSubLevels(index);
              index=index+1;
        }
        let RequestorRemarksCharacterCount = this.props.data.RequestorRemarks.length;
        var maxCharReq = 200;
        this.setState({
              offering:  _.cloneDeep(this.props.data),
              SubOffering : subOfferingValues,
              RequestorRemarksCharacterCount: maxCharReq - (RequestorRemarksCharacterCount)
            });
    }
  } 

  handleSubLevels(index){
    let subOfferingValues = this.state.SubOffering;
    subOfferingValues.map( each => {
      if(each.Id==1)
        each.addNext = subOfferingValues.length==2 ? true : false;
    })
    let subOfferingdup = subOfferingValues;
    let check = subOfferingdup.slice(index+1);
    let ind = index+1;
    check.map( each => {
        each.Id = ind+1;
        each.Name = each.Name.includes(i18n.t("Sub_Offering")) ? 
                    (ind+1) + " " + i18n.t("Sub_Offering") : 
                    (ind+1) + " " + i18n.t("Sub Level");
        each.placeholder = each.placeholder.includes(i18n.t("Sub_Offering")) ? 
                          (ind+1) + " " + i18n.t("Sub_Offering") :
                          (ind+1) + " " + i18n.t("Sub Level")
        ind=ind+1;
    })
    this.setState({
      SubOffering : subOfferingValues
    });
  }

  onOfferingNameChange(event) {
    let errors = {};
    this.setState({
      errors: errors,
    });
    const offering = this.state.offering;
    offering.Offering = event.target.value;

    this.setState({
      offering: offering,
    });
  }

  onProcessChange(event) {
    let errors = {};
    this.setState({
      errors: errors,
    });
    const offering = this.state.offering;
    offering.Process = event.target.value;

    this.setState({
      offering: offering,
    });
  }

  onStatusChange(event) {
    const offering = this.state.offering;
    offering.IsActive = event.target.checked;
    this.setState({
      offering: offering,
    });
  }

  onValueBlockChange(event) {
    this.setState({ errors: {} });
    const offering = this.state.offering;
    offering.IsValueBlock = event.target.checked;
    this.setState({
      offering: offering,
    });
  }

  onRemarksChange(event) {
    this.setState({ errors: {} });
    var maxChar = 200;
    const offering = this.state.offering;
    offering.RequestorRemarks = event.target.value;
    let val = maxChar - (event.target.value.length);
    this.setState({
      offering: offering,
      RequestorRemarksCharacterCount: val
    });
  }

  onRelationBlockChange(event) {
    this.setState({ errors: {} });
    const offering = this.state.offering;
    offering.IsRelatorBlock = event.target.checked;
    this.setState({
      offering: offering,
    });
  }

  onNotificationBlockChange(event) {
    const offering = this.state.offering;
    offering.IsNotificationBlock = event.target.checked;
    this.setState({
      offering: offering,
    });
  }

  onArrowBlockChange(event) {
    const offering = this.state.offering;
    offering.IsArrowBlock = event.target.checked;
    this.setState({
      offering: offering,
    });
  }

  onDetailsLinkChange(event) {
    const offering = this.state.offering;
    offering.IsDetailsLink = event.target.checked;
    this.setState({
      offering: offering,
    });
  }
  handleAvailableforDealCreation(event) {
    const offering = this.state.offering;
    offering.AvailableForDealCreation = event.target.checked;
    this.setState({
      offering: offering,
    });
  }
  validateForm() {
    const offering = this.state.offering;
    let errors = {};
    let formIsValid = true;
    const subOfferingValues = this.state.SubOffering;
let invalidDescriptionText=InvalidDescription();
    subOfferingValues.map( (each,index) => {
      if(each.text == null || !each.text.trim()){
          if(index==0){
            formIsValid = false;
            errors[`${each.Name}`] = "Offering Name is required"
          }
          else if(index==subOfferingValues.length-1){
            formIsValid = false;
            errors[`${each.Name}`] = "Sub Offering Name is required"
          }
          else{
            formIsValid = false;
            errors[`${each.Name}`] = i18n.t("Sub Level") + " " + each.Id + " " + i18n.t("is required")
          }
      }
      else{
        var isValid = regexForConfMasterFields.test(each.text);
        if(!isValid){
          if(index==0){
            formIsValid = false;
            errors[`${each.Name}`] = "Offering Name contains Special characters";
          }
          else if(index==subOfferingValues.length-1){
            formIsValid = false;
            errors[`${each.Name}`] = "Sub Offering Name contains Special characters";
          }
          else{
            formIsValid = false;
            errors[`${each.Name}`] = i18n.t("Sub Level") + " " + each.Id + " " + i18n.t("contains Special characters")
          }
        }
        else{
          let isValidInputtedText = isValidText(each.text);
          if(!isValidInputtedText){
            if(index==0){
              formIsValid = false;
              errors[`${each.Name}`] = invalidDescriptionText;
            }
            else if(index==subOfferingValues.length-1){
              formIsValid = false;
              errors[`${each.Name}`] = invalidDescriptionText;
            }
            else{
              formIsValid = false;
              errors[`${each.Name}`] = i18n.t("Sub Level") + " " + each.Id + " " + invalidDescriptionText;
            }
        }
      }
    }
    })

    if (offering["RequestorRemarks"] == null) {
      formIsValid = false;
      errors["RequestorRemarks"] = "Requestor Remarks are required";
    } 
    else if (!offering["RequestorRemarks"].trim()) {
      formIsValid = false;
      errors["RequestorRemarks"] = "Requestor Remarks are required";
    } 
    else {
      var isValid = regexForTextAreas.test(offering["RequestorRemarks"]);
      if (!isValid) {
        formIsValid = false;
        errors["RequestorRemarks"] = "Requestor Remarks contains Special characters";
      }
      else{
        if(!isValidText(offering["RequestorRemarks"])){
          formIsValid = false;
          errors["RequestorRemarks"] = invalidDescriptionText;
        }
      }
    }

    this.setState({
      errors: errors,
    });
    if (formIsValid) {
      this.setState({
        formIsValid: true,
      });
    } else {
      this.setState({
        formIsValid: false,
      });
    }
    return formIsValid;
  }
  
  characterCount = (event,value) => {    
    this.setState({ errors: {} })
    var maxChar = 50;
    let subOfferingValues=this.state.SubOffering;
    subOfferingValues.map( each => {
      if(each.Id==value)
      {
        let val=maxChar - (event.target.value.length);
        each.characterCount=val;
        each.text=event.target.value;
      }
    })
    this.setState({
      SubOffering:subOfferingValues
    })
  }

  getDropdownItemValue(item, id) {
    return id === 1 ? item.Offering : item[`Sub_Level_${id}`];
  }

  handleDropdownSelection(value, item) {
    let valueSelected = this.getDropdownItemValue(item, value);
    var maxChar = 50;
    let val;
    let subOfferingValues = this.state.SubOffering;
    let prevDropdownData = this.state.dropdownData;
    
    this.setState({ errors: {} })

    subOfferingValues.map(each => {
      if(each.Id == value){
        val = maxChar - (valueSelected.length);
        each.characterCount = val;
        each.text = valueSelected;
      }
    })

    this.setState({
      SubOffering:subOfferingValues
    })

    const filteredDropdownData = this.state.dropdownData[value - 1].data.filter((each) => { 
      return each.OfferingId === item.OfferingId 
    });

    const newDropdownData = prevDropdownData.map(item => {
      if (item.id === value + 1) {
        return {
          ...item,
          data: filteredDropdownData,
        }
      }
      return item;
    })

    this.setState({
      dropdownData: newDropdownData,
    });
  }

  showNextLevel = (index) => {
    this.setState({ errors: {} })
    let subOfferingValues=this.state.SubOffering;
    if(index<=4 && subOfferingValues.length<=5){
        let value={
          Name: (index+1) + " " +i18n.t("Sub Level"),
          Id: index+1,
          characterCount: 50,
          placeholder: (index+1) + " " +i18n.t("Sub Level"),
          addNext: true,
          text: "",
          isSubOffering: false,
          OfferingId: 0,
          ParentId: null
        }
        subOfferingValues.splice(index,0,value)
        this.handleSubLevels(index);
    }
  }

  removeSubLevel = (index) => {
    this.setState({ errors: {} })
    let subOfferingValues = [];
    this.state.SubOffering.map( each => {
      if(each.Id != index){
        subOfferingValues.push(each);
      }
    })
    subOfferingValues.map( each => {
      if(each.Id == 1)
        each.addNext = subOfferingValues.length==2 ? true : false;
    })
    let check = subOfferingValues.slice(index-1);
    check.map( each => {
        each.Id = index;
        each.Name = each.Name.includes(i18n.t("Sub_Offering")) ?
                   (index) + " " + i18n.t("Sub_Offering") : 
                   (index) + " " + i18n.t("Sub Level");
        each.placeholder = each.placeholder.includes(i18n.t("Sub_Offering")) ? 
                    (index) + " " + i18n.t("Sub_Offering") : 
                    (index) + " " + i18n.t("Sub Level")
        index = index+1;
    })
    this.setState({
      SubOffering: subOfferingValues
    });
  }
  
  render() {
    const { show, offering, errors, apivalue } = this.state;

    return (
      <>
        {/* <Header /> */}
        <Modal
          show={show}
          onHide={this.handleHide.bind(this)}
          backdrop="static"
          className="modal fade addadminModal addoffering-modal"
        >
          <Modal.Header closeButton>
            {!this.props.data ? (
              <h4 className="modal-title" id="dealprofile">
                Add Offering
              </h4>
            ) : (
              <h4 className="modal-title" id="dealprofile">
                Edit Offering
              </h4>
            )}
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="form-section">
                <div className="row">
                  <div className="col-sm-7">
                    <div className="form-section">
                      <ul className="offering-inputfields common-formgrouplabel">
                        {this.state.SubOffering.map((each,index) => {
                          let rem_value=(each.Id-1)+"rem";
                          return (
                            <li style={{ marginLeft: `${rem_value}`}}>
                              {
                                this.props.data || (this.state.showTextbox.show && each.Id >= this.state.showTextbox.showTextboxFrom || each.isSubOffering) ? (
                                  <div className={
                                    this.props.data && (each.Id==1 || each.Id==this.state.SubOffering.length) ?
                                    "form-group border-group disabled" :
                                    "form-group border-group"
                                }>
                                  <label for="Offering" className="form-label">
                                      {each.Name} {" "}
                                      <span className="required">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    maxlength="50"
                                    className="form-control"
                                    placeholder={each.placeholder}
                                    onChange={(e)=>this.characterCount(e,each.Id)}
                                    value={each.text}
                                    disabled={
                                      this.props.data && (each.Id==1 || each.Id==this.state.SubOffering.length) ? 
                                      true :
                                      false }
                                  />
                                  {
                                    !(each.Id==1 || each.Id==this.state.SubOffering.length) && 
                                    <span 
                                      className="remove-sublevel-icon" 
                                      data-tip data-for="closeIcon"
                                      onClick={()=>this.removeSubLevel(each.Id)}
                                    >
                                      <img src={CloseIcon} />
                                      <ReactTooltip place="bottom" effect="solid" id="closeIcon">
                                        <span>{i18n.t("Remove Sub Levels")}</span>
                                      </ReactTooltip>
                                    </span>
                                  }
                                  <span className="charcount-label">{each.characterCount}{i18n.t("/50 Characters")}</span>
                                  </div>
                                ) : (
                                  <div
                                    className='position-relative d-flex offering-popup-dropdown-container'
                                    style={{ marginRight: '1rem'}}
                                  >
                                    <DropdownButton
                                      menuAlign="left"
                                      id="Offeringbutton"
                                      title={
                                        each.text.length > 0 ? each.text : each.placeholder
                                      }
                                      className={`offering-popup-dropdown ${each.text.length === 0 ? 'offering-popup-dropdown-placeholder': ''}`}
                                    >
                                        <div className='offering-popup-dropdown-wrapper'>
                                          {
                                            this.state.dropdownData.map(i => {
                                              return (
                                                each.Id === i.id && _.uniqBy(i.data, 'Offering').map((item) => {
                                                  return (
                                                    this.getDropdownItemValue(item, each.Id) && 
                                                    <Dropdown.Item
                                                      href="javascript:void(0);"
                                                      onClick={(e) => {
                                                        this.handleDropdownSelection(each.Id, item);
                                                      }}
                                                    >
                                                      <span
                                                        className='offering-popup-dropdown-text'
                                                        role="button"
                                                        tabIndex={0}
                                                      >
                                                        {this.getDropdownItemValue(item, each.Id)}
                                                      </span>
                                                    </Dropdown.Item>
                                                  )
                                                })
                                              )  
                                            })
                                          }
                                          <Dropdown.Item href="javascript:void(0);" onClick={() => {
                                            this.setState({
                                              showTextbox : {
                                                show: true,
                                                showTextboxFrom: each.Id,
                                              }
                                            })
                                          }}>
                                            <span
                                              className=''
                                              role="button"
                                              tabIndex={0}
                                            >
                                              Add New
                                            </span>
                                          </Dropdown.Item>
                                        </div>
                                    </DropdownButton>
                                    {
                                      !(each.Id==1 || each.Id==this.state.SubOffering.length) && 
                                      <span 
                                        className="remove-sublevel-icon" 
                                        data-tip data-for="closeIcon"
                                        onClick={()=>this.removeSubLevel(each.Id)}
                                      >
                                        <img src={CloseIcon} />
                                        <ReactTooltip place="bottom" effect="solid" id="closeIcon">
                                          <span>{i18n.t("Remove Sub Levels")}</span>
                                        </ReactTooltip>
                                      </span>
                                    }
                                  </div>
                                )
                              }
                               
                              {each.addNext && 
                                <div className="form-fieldactions">
                                  <span 
                                    className={this.state.SubOffering.length == 6 ? "disabled" : ""} 
                                    data-tip data-for="addIcon" 
                                    onClick={()=>this.showNextLevel(each.Id)}
                                  >
                                    <i className="fal fa-plus-circle"></i>
                                    <ReactTooltip place="bottom" effect="solid" id="addIcon">
                                      {i18n.t("Add Sub Levels")}
                                    </ReactTooltip>
                                  </span>
                                </div>
                              }
                              
                              <div className="l-shapelink">
                                <div className="l-shapelink-inner"></div>
                              </div>
                              
                              {this.state.errors[`${each.Name}`] && 
                                <span className="sublevelerrMsg">
                                  {this.state.errors[`${each.Name}`]}
                                </span>
                              }
                            </li>
                          )})
                        }
                      </ul> 
                    </div>
                  </div>
                  <div className="col-sm-5">

                    <div className="form-section common-formgrouplabel mb-4 remarks-textarea">
                        <div className={this.props.data != null ? "form-group border-group disabled" : "form-group border-group"} >
                          <label for="Offering" className="form-label">Requestor Remarks{" "}<span className="required">*</span></label>
                          <textarea
                            rows="3"
                            maxlength="200"
                            className="form-control"
                            id="remarks"
                            placeholder="Requestor Remarks"
                            onChange={this.onRemarksChange.bind(this)}
                            value={offering.RequestorRemarks}
                          />
                          <span className="charcount-label">{this.state.RequestorRemarksCharacterCount}/200 Characters</span>
                        </div>
                        <span className="errorMsg">{this.state.errors.RequestorRemarks}</span>
                    </div>

                    {this.props.data &&
                    <div className="form-section common-formgrouplabel mb-4">
                      <div className="form-group border-group disabled">
                              <label for="Offering" className="form-label">Approver Remarks</label>
                              <textarea
                                rows="3"
                                maxlength="200"
                                id="ClientContact1"
                                className="form-control"
                                disabled={this.props.data || this.state.apivalue}
                                value={this.props.data.ApproverRemarks}
                              />
                      </div>
                      </div>
                    }

                    <div className="togglegroup-section mb-2">

                      {this.props.data ? (
                        this.props.data.Status === "Approved" ? (
                          <div className="toggle-group form-group custom-toggleswitch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="IsActive"
                              id="IsActive"
                              onChange={this.onStatusChange}
                              defaultChecked={offering.IsActive}
                            />
                            <label htmlFor="IsActive">
                              Is Active
                            </label>
                            <div className="onoffswitch" aria-hidden="true">
                              <div className="onoffswitch-label">
                                <div className="onoffswitch-inner"></div>
                                <div className="onoffswitch-switch"></div>
                              </div>
                            </div>
                            <span className="toggleswitch-text">Is Active</span>
                          </div>) : (
                          <div className="toggle-group form-group custom-toggleswitch">
                            <input
                              className="form-check-input"
                              readOnly
                              type="checkbox"
                              name="IsActive"
                              id="IsActive"
                              onChange={this.onStatusChange}
                              defaultChecked={offering.IsActive}
                            />
                            <label htmlFor="IsActive">
                              Is Active
                            </label>
                            <div className="onoffswitch" aria-hidden="true">
                              <div className="onoffswitch-label">
                                <div className="onoffswitch-inner"></div>
                                <div className="onoffswitch-switch"></div>
                              </div>
                            </div>
                            <span className="toggleswitch-text">Is Active</span>
                          </div>
                        )
                      ) : (
                        <div className="toggle-group form-group custom-toggleswitch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="IsActive"
                            id="IsActive"
                            onChange={this.onStatusChange}
                            defaultChecked={offering.IsActive}
                          />
                          <label htmlFor="IsActive">
                            Is Active
                          </label>
                          <div className="onoffswitch" aria-hidden="true">
                            <div className="onoffswitch-label">
                              <div className="onoffswitch-inner"></div>
                              <div className="onoffswitch-switch"></div>
                            </div>
                          </div>
                          <span className="toggleswitch-text">Is Active</span>
                        </div>
                      )}


                      {this.props.data ? (
                        this.props.data.Status === "Approved" ? (
                          <div className="toggle-group form-group custom-toggleswitch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="Relator"
                              id="Relator"
                              onChange={this.onRelationBlockChange}
                              defaultChecked={offering.IsRelatorBlock}
                            />
                            <label htmlFor="Relator">
                              Relator
                            </label>
                            <div className="onoffswitch" aria-hidden="true">
                              <div className="onoffswitch-label">
                                <div className="onoffswitch-inner"></div>
                                <div className="onoffswitch-switch"></div>
                              </div>
                            </div>
                            <span className="toggleswitch-text">Relator</span>
                          </div>) : (
                          <div className="toggle-group form-group custom-toggleswitch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              readOnly
                              name="Relator"
                              id="Relator"
                              onChange={this.onRelationBlockChange}
                              defaultChecked={offering.IsRelatorBlock}
                            />
                            <label htmlFor="Relator">
                              Relator
                            </label>
                            <div className="onoffswitch" aria-hidden="true">
                              <div className="onoffswitch-label">
                                <div className="onoffswitch-inner"></div>
                                <div className="onoffswitch-switch"></div>
                              </div>
                            </div>
                            <span className="toggleswitch-text">Relator</span>
                          </div>
                        )
                      ) : (
                        <div className="toggle-group form-group custom-toggleswitch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="Relator"
                            id="Relator"
                            onChange={this.onRelationBlockChange}
                            defaultChecked={offering.IsRelatorBlock}
                          />
                          <label htmlFor="Relator">
                            Relator
                          </label>
                          <div className="onoffswitch" aria-hidden="true">
                            <div className="onoffswitch-label">
                              <div className="onoffswitch-inner"></div>
                              <div className="onoffswitch-switch"></div>
                            </div>
                          </div>
                          <span className="toggleswitch-text">Relator</span>
                        </div>
                      )}


                    </div>

                    <div className="togglegroup-section mb-2">

                      {this.props.data ? (
                        this.props.data.Status === "Approved" ? (
                          <div className="toggle-group form-group custom-toggleswitch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="demoProject"
                              id="demoProject"
                              onChange={this.handleAvailableforDealCreation}
                              defaultChecked={offering.AvailableForDealCreation}
                            />
                            <label htmlFor="demoProject">
                              Available for non demo project 
                            </label>
                            <div className="onoffswitch" aria-hidden="true">
                              <div className="onoffswitch-label">
                                <div className="onoffswitch-inner"></div>
                                <div className="onoffswitch-switch"></div>
                              </div>
                            </div>
                            <span className="toggleswitch-text">Available for non demo project </span>
                          </div>) : (
                          <div className="toggle-group form-group custom-toggleswitch">
                            <input
                              className="form-check-input"
                              readOnly
                              type="checkbox"
                              name="demoProject"
                              id="demoProject"
                              onChange={this.handleAvailableforDealCreation}
                              defaultChecked={offering.AvailableForDealCreation}
                            />
                            <label htmlFor="demoProject">
                              Available for non demo project 
                            </label>
                            <div className="onoffswitch" aria-hidden="true">
                              <div className="onoffswitch-label">
                                <div className="onoffswitch-inner"></div>
                                <div className="onoffswitch-switch"></div>
                              </div>
                            </div>
                            <span className="toggleswitch-text">Available for non demo project </span>
                          </div>
                        )
                      ) : (
                        <div className="toggle-group form-group custom-toggleswitch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="demoProject"
                            id="demoProject"
                            onChange={this.handleAvailableforDealCreation}
                            defaultChecked={offering.AvailableForDealCreation}
                          />
                          <label htmlFor="demoProject">
                            Available for non demo project 
                          </label>
                          <div className="onoffswitch" aria-hidden="true">
                            <div className="onoffswitch-label">
                              <div className="onoffswitch-inner"></div>
                              <div className="onoffswitch-switch"></div>
                            </div>
                          </div>
                          <span className="toggleswitch-text">Available for non demo project </span>
                        </div>
                      )}
                    </div>

                    <div className="roleactions-block form-group">
                      <span className="role-label">Benchmark Attributes{" "}<span className="required">*</span></span>
                      <label className="form-check form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          onChange={this.handleDealLevelRadioButtonChange}
                          checked={offering.IsBatDealLevel}
                          name="dealLevelBAT"
                          value="Deal Level"
                        />
                        <span className="checkmark"></span>
                        <span className="form-check-label">Deal Level</span>
                      </label>
                      <label className="form-check form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          onChange={this.handleDealLevelRadioButtonChange}
                          checked={!offering.IsBatDealLevel}
                          name="dealLevelBAT"
                          value="Benchmark at Sub Offering Level"
                        />
                        <span className="checkmark"></span>
                        <span className="form-check-label">Sub Offering Level</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
          {
            <Modal.Footer>
              <div className="modal-btn adminpopup-footer">
              <button className="btn btn-default btn-sm mr-2" onClick={this.handleHide.bind(this)}>
                 Cancel
                </button>
                {!this.props.data ? (
                  <input
                    type="Button"
                    className="btn btn-primary btn-sm"
                    onClick={() => this.handleSave("Save")}
                    value="Save"
                  />
                ) : this.props.data.Status === "Approved" ? (
                  <input
                    type="Button"
                    className="btn btn-primary btn-sm"
                    onClick={() => this.handleSave("Update")}
                    value="Update"
                  />
                ) : null}
              </div>
            </Modal.Footer>
          }
        </Modal>
      </>
    );
  }
}
